.promotion-plans {
  max-width: 100%; /* Allow full width */
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
}

table {
  width: 100%; /* Set table width to 100% */
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center; /* Center align for header */
}

th {
  background-color: #f4f4f4;
}

td:first-child {
  text-align: left; /* Left align for first column */
}

.buy-now {
  display: block;
  margin: 20px auto; /* Center the button */
  padding: 10px 20px;
  background-color: #ffcc00;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.buy-now:hover {
  background-color: #e6b800;
}

.btn-buy-now {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #4f973d; /* Red outline */
  border-radius: 50px; /* Rounded corners */
  font-size: 16px;
  color: #4f973d; /* Red text */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.btn-buy-now img {
  margin-right: 10px; /* Space between the image and text */
  width: 20px;
  height: 20px;
}

.btn-buy-now:hover {
  background-color: #71C55D; /* Red background on hover */
  color: white; /* White text on hover */
}

.btn-livedemo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #4449e9; /* Red outline */
  border-radius: 50px; /* Rounded corners */
  font-size: 16px;
  color: #4449e9; /* Red text */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.btn-livedemo img {
  margin-right: 10px; /* Space between the image and text */
  width: 20px;
  height: 10px !important;
}

.btn-livedemo:hover {
  background-color: #4449e9; /* Red background on hover */
  color: white; /* White text on hover */
}